 /* eslint-disable */
import React from 'react'
import { graphql, Link } from 'gatsby'


import Img from 'gatsby-image'

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"



export default ({ data, pageContext: { node, locale, pagePath } }) => {



    let pageData = data.pageData.edges[0].node
    let globalData = data.globalData.edges[0].node

    let page_title_local = pageData.frontmatter[locale + "_title_01"] + " | " + globalData.frontmatter[locale + "_page_title_wooden_doors_prefix"] + " | " + globalData.frontmatter[locale + "_page_title_global_prefix"] + " " + globalData.frontmatter[locale + "_page_title_since_2001_prefix"]  
    let page_meta_description = pageData.frontmatter[locale + "_meta_desc"]
    let page_meta_keywords = pageData.frontmatter[locale + "_meta_keywords"]


    return (
        <React.Fragment>


                    <SEO 
                        description={page_meta_description}
                        keywords={page_meta_keywords}
                        pathname={pagePath}
                        title={page_title_local}
                    />
                    <Header 
                        locale={locale}
                        pagePath={pagePath}
                    />


                    <div className="container">

                        <div className="row">
                            <div className="col-12 col-md-7">
                                <h1>{pageData.frontmatter[locale + "_title_01"]}</h1>
                                <p>{pageData.frontmatter[locale + "_text_01"]}</p>

                            </div>
                            <div className="col-12 col-md-5">
                                <h3>{pageData.frontmatter[locale + "_title_02"]}</h3>
                                <p>{pageData.frontmatter[locale + "_text_02"]}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3392.4941760822485!2d34.68014231515637!3d31.7570049812919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe0a82c2fe5d67e52!2z15DXqNeYINeT15XXqCDXk9ec16rXldeqINee16LXpSDXntec15A!5e0!3m2!1sen!2s!4v1492300410639" width="100%" height="450" frameBorder="0" allowFullScreen></iframe>
                            </div>

                        </div>


                        <div id="contactDetailsSection" className="row">
                            <div className="col-12 col-sm-4">
                                <i class="fas fa-mobile-alt fa-5x"></i>
                                <p className="contactUsInfo">{pageData.frontmatter[locale + "_text_mobile_phone"]}</p>
                            </div>
                            <div className="col-12 col-sm-4">
                                <i class="far fa-clock fa-5x"></i>
                                <p className="contactUsInfo">{pageData.frontmatter[locale + "_text_working_hours"]}</p>
                            </div>
                            <div className="col-12 col-sm-4">
                                <i class="fas fa-fax fa-5x"></i>
                                <p className="contactUsInfo">{pageData.frontmatter[locale + "_text_fax"]}</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <a className="twitter-timeline" href="https://twitter.com/artdoor_design?ref_src=twsrc%5Etfw">Tweets by artdoor_design</a> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                            </div>
                        </div>
                    </div>

                    <Footer 
                        locale={locale}
                        pagePath={pagePath}
                    />



        </React.Fragment>
    
    )
}




export const md_contact = graphql`query md_contact {
    pageData: 
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "page_contact"}}}) {
      edges {
        node {
          frontmatter {
            slug
            he_meta_desc
            he_meta_keywords
            he_title_01
            he_title_02
            he_text_02
            he_text_01
            he_title_mobile_phone
            he_title_working_hours
            he_text_mobile_phone
            he_text_working_hours
            he_title_fax
            he_text_fax
            pr_meta_desc
            pr_meta_keywords
            pr_title_01
            pr_title_02
            pr_text_02
            pr_text_01
            pr_title_mobile_phone
            pr_title_working_hours
            pr_text_mobile_phone
            pr_text_working_hours
            pr_title_fax
            pr_text_fax
            es_meta_desc
            es_meta_keywords
            es_title_01
            es_title_02
            es_text_02
            es_text_01
            es_title_mobile_phone
            es_title_working_hours
            es_text_mobile_phone
            es_text_working_hours
            es_title_fax
            es_text_fax
            ru_meta_desc
            ru_meta_keywords
            ru_title_01
            ru_title_02
            ru_text_02
            ru_text_01
            ru_title_mobile_phone
            ru_title_working_hours
            ru_text_mobile_phone
            ru_text_working_hours
            ru_title_fax
            ru_text_fax
            fr_meta_desc
            fr_meta_keywords
            fr_title_01
            fr_title_02
            fr_text_02
            fr_text_01
            fr_title_mobile_phone
            fr_title_working_hours
            fr_text_mobile_phone
            fr_text_working_hours
            fr_title_fax
            fr_text_fax
            en_meta_desc
            en_meta_keywords
            en_title_01
            en_title_02
            en_text_02
            en_text_01
            en_title_mobile_phone
            en_title_working_hours
            en_text_mobile_phone
            en_text_working_hours
            en_title_fax
            en_text_fax
          }
        }
      }
    }
    globalData: 
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "global_data"}}}) {
        edges {
            node {
                id
                frontmatter {
                    he_page_title_global_prefix
                    he_page_title_since_2001_prefix
                    he_page_title_wooden_doors_prefix
                    he_door_series
                    fr_page_title_global_prefix
                    fr_page_title_since_2001_prefix
                    fr_page_title_wooden_doors_prefix
                    fr_door_series
                    en_page_title_global_prefix
                    en_page_title_since_2001_prefix
                    en_page_title_wooden_doors_prefix
                    en_door_series
                    pr_page_title_global_prefix
                    pr_page_title_since_2001_prefix
                    pr_page_title_wooden_doors_prefix
                    pr_door_series
                    es_page_title_global_prefix
                    es_page_title_since_2001_prefix
                    es_page_title_wooden_doors_prefix
                    es_door_series
                    ru_page_title_global_prefix
                    ru_page_title_since_2001_prefix
                    ru_page_title_wooden_doors_prefix
                    ru_door_series
                }
            }
        }
    }
  }`